export const vegetablesData = [
  {
    index: 0,
    information: "Pizza-Production",
    image: "/img/pizza.svg",
  },
  {
    index: 1,
    information: "Vegetable-Production ",
    image: "/img/vegetables.svg",
  },
  {
    index: 2,
    information: "Sweets-Industry",
    image: "/img/inclined-chocolate-bar.svg",
  },
  {
    index: 3,
    information: "Meat-Processing",
    image: "/img/meat.svg",
  },
  {
    index: 4,
    information: "Sugar-Refinery",
    image: "/img/sugar.svg",
  },
  {
    index: 5,
    information: "Paper-Recycling",
    image: "/img/paper-recycle.svg",
  },
  {
    index: 6,
    information: "Brick-Manufacturing",
    image: "/img/brickwall.svg",
  },
  {
    index: 7,
    information: "Wholesale-Bakeries",
    image: "/img/bakery.svg",
  },
];

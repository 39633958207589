import {
  AppBar,
  Container,
  createStyles,
  fade,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      animation: `$fadein 0.2s `,
      height: "90px",
      backgroundColor: "transparent",
      alignItems: "center",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        left: 0,
        height: "60px",
      },
    },
    intermediateToolbar: {
      backgroundColor: fade("#232323", 0.8),
      height: "90px",
      transition: theme.transitions.create(["background-color"], {
        duration: "0.8s",
      }),
      backdropFilter: "blur(2px)",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        height: "60px",
      },
    },
    finalToolbar: {
      backgroundColor: fade("#232323", 0.7),
      height: "90px",
      transition: theme.transitions.create(["background", "border-bottom"], {
        duration: "0.9s",
      }),

      backdropFilter: "blur(2px)",
      borderBottom: "none",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        height: "60px",
      },
    },
    rootHide: {
      backgroundColor: fade("#232323", 1),
      animation: `$fadeout 0.2s`,
      visibility: "hidden",
    },
    container: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    navItems: {
      display: "flex",
      justifyContent: "space-between",
      zIndex: 5,
      width: "100%",
      alignItems: "center",
    },

    toolbar: {
      justifyContent: "space-between",
      cursor: "pointer",
    },

    iconContainer: {
      position: "absolute",
      height: "100%",
      right: 0,
      marginRight: theme.spacing(4),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        marginRight: theme.spacing(2),
        position: "relative",
        height: "auto",
      },
    },
    logo: {
      cursor: "pointer",
    },
    phoneWrapper: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    contactNumber: {
      color: theme.palette.common.white,
      textDecoration: "none",
      display: "block",

      marginLeft: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginRight: theme.spacing(4),
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "@keyframes fadein": {
      from: {
        height: "0",
        opacity: "0",
      },
      to: {
        height: "64px",
        opacity: "0.9",
      },
    },

    "@keyframes fadeout": {
      from: {
        height: "64px",
        opacity: "0.9",
        visibility: "visible",
      },
      to: {
        height: "0px",
        opacity: "0",

        visibility: "hidden",
      },
    },
  })
);

/* eslint-disable-next-line */
interface NavbarProps {
  // logo: string;
}

export const Navbar = (props: NavbarProps) => {
  // const [showNav, setShowNav] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<number>();
  const classes = useStyles(props);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  return (
    <AppBar
      //  className={classes.root}
      className={classNames(classes.root, {
        [classes.intermediateToolbar]:
          scrollPosition !== undefined && scrollPosition > 53,
      })}
      position="fixed"
      elevation={0}
    >
      <Container disableGutters className={classes.container}>
        <Toolbar className={classes.navItems}>
          <img
            src="/img/plan_be_logo.svg"
            className={classes.logo}
            alt="grimme logo"
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          />

          {scrollPosition !== undefined && scrollPosition > 53 && (
            <div className={classes.phoneWrapper}>
              <img src="/img/phone.svg" alt="" />
              <Typography
                variant="h6"
                component="a"
                href="tel:0549196928947"
                className={classes.contactNumber}
              >
                05491 969 289 47
              </Typography>
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;

import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import App from "./App";
import "./index.css";

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/en">
        <App language="en" />
      </Route>
      <Route path="/de">
        <App language="de" />
      </Route>
      <Redirect exact from="*" to="/en" />
    </Switch>
  </Router>,
  document.getElementById("root")
);

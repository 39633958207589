import {
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Button } from "../../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctaRoot: {
      backgroundBlendMode: "multiply",
      backgroundSize: "cover",
      color: theme.palette.common.white,
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),
      position: "relative",
      textAlign: "center",

      marginTop: theme.spacing(15),

      [theme.breakpoints.up("md")]: {
        backgroundPositionY: "-158px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        backgroundRepeat: "no-repeat",
        textAlign: "left",
        marginTop: theme.spacing(5),
      },
    },
    ctaContent: {
      maxWidth: 600,
      margin: "auto",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(4),
        margin: 0,
      },
    },
    ctaDivider: {
      "&::after": {
        [theme.breakpoints.down("sm")]: {
          margin: 0,
          marginRight: "auto",
        },
      },
    },
    registerButtonWrapper: {
      textDecoration: "none",
    },
  })
);

/* eslint-disable-next-line */
type CallToActionProps = React.PropsWithChildren<{
  title: string;
  buttonText: string;
  img?: string;
}>;

export const CallToAction = (props: CallToActionProps) => {
  const classes = useStyles(props);
  const imageUrlStyle = `url('${
    props.img || ""
  }'), linear-gradient(90deg, #232323bf 10%, #ffffff 100%)`;
  return (
    <>
      <Container
        className={classes.ctaRoot}
        maxWidth={false}
        style={{
          backgroundImage: imageUrlStyle,
        }}
        disableGutters
      >
        <Container>
          <Typography variant="h2">{props.title}</Typography>
          <Typography variant="body1" className={classes.ctaContent}>
            {props.children}
          </Typography>
          <a
            href={"https://abfrage.typeform.com/to/wBFsTOKI"}
            title={""}
            target="_blank"
            className={classes.registerButtonWrapper}
            rel="noreferrer"
          >
            <Button variant="default" modifier="bright">
              {props.buttonText}
            </Button>
          </a>
        </Container>
      </Container>
    </>
  );
};

export default CallToAction;

import { IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      right: theme.spacing(4),
      bottom: theme.spacing(2),

      flexDirection: "column",
      display: "flex",
      backgroundColor: "red",
      zIndex: 200,
    },
    contactSetailsWrapper: {
      padding: theme.spacing(2),
    },
    closeIconWrapper: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        right: theme.spacing(0.5),
        top: theme.spacing(0.5),
      },
    },
    contactAssistantPopover: {
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      color: theme.palette.common.white,
      right: "0px",
      bottom: "110px",
      width: "250px",
      borderRadius: theme.spacing(0.5),
      [theme.breakpoints.down("sm")]: {
        bottom: "80px",
        width: "200px",
      },
      "&::after": {
        content: "''",
        width: "18px",
        height: "18px",
        transform: "rotate(-45deg)",
        background: theme.palette.primary.main,
        position: "absolute",

        bottom: "-5px",
        right: "20px",
      },
    },

    callUsWrapper: {
      padding: theme.spacing(1, 0),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0.5, 0),
        marginRight: theme.spacing(1),
      },
    },
    callUsText: {
      padding: theme.spacing(1, 0),
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    avatar: {
      bottom: 0,
      position: "absolute",
      right: 0,
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[3],
      padding: theme.spacing(0),
    },
    avatarIcon: {
      width: "80px",
      height: "80px",
      borderRadius: "50px",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        width: "50px",
        height: "50px",
      },
    },
    contactNumber: {
      color: theme.palette.common.white,
      textDecoration: "none",
      display: "block",
      transition: "0.3s ease-in-out",
      whiteSpace: "nowrap",
      fontWeight: 600,
    },
    detailAddition: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    phoneWrapper: {
      display: "flex",
      alignItems: "center",
    },
    phoneIcon: {
      width: "20px",
      marginRight: theme.spacing(1),
    },
  })
);

export function ContactAssistant(props: any) {
  const classes = useStyles();
  useEffect(() => {
    setTimeout(() => setShow(true), 5000);
  }, []);

  const [show, setShow] = useState(false);

  return (
    <div className={classes.root} data-testid="ca-wrapper">
      {show && (
        <div className={classes.contactAssistantPopover}>
          <div className={classes.contactSetailsWrapper}>
            <div
              className={classes.closeIconWrapper}
              onClick={() => setShow(false)}
            >
              <CloseIcon />
            </div>
            <Typography variant="h6">Any questions?</Typography>
            <Typography
              component="p"
              variant="body1"
              className={classes.callUsWrapper}
            >
              Hello i'm robin If you have any questions, please give me a call!
            </Typography>

            <div className={classes.phoneWrapper}>
              <img src="/img/phone.svg" alt="" className={classes.phoneIcon} />
              <Typography
                component="a"
                href="tel:0549196928947"
                className={classes.contactNumber}
              >
                05491 969 289 47
              </Typography>
            </div>
          </div>
        </div>
      )}
      <IconButton className={classes.avatar} onClick={() => setShow(!show)}>
        <img
          src="/img/robin.jpg"
          className={classes.avatarIcon}
          alt="contact-assistant-avatar"
        />
      </IconButton>
    </div>
  );
}

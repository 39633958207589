export const data = [
  {
    index: 0,
    information: "Low mainteance",
    image: "/img/wrench.svg",
  },
  {
    index: 1,
    information: "Tailored for you",
    image: "/img/solving.svg",
  },
  {
    index: 2,
    information: "Low process cost",
    image: "/img/dollar.svg",
  },
];

import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { TitleDivider } from "../../components";
import { footerBarLinks } from "../../data/footerData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      marginTop: theme.spacing(10),
    },
    contacts: {
      backgroundColor: theme.palette.grey[900],
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      "& a": {
        marginRight: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
          marginRight: theme.spacing(2),
        },
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
    nav: {
      backgroundColor: theme.palette.grey[600],
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      textAlign: "center",
      "& a": {
        color: theme.palette.common.white,
        textDecoration: "none",
      },
      "& > a": {
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
      },
    },
    footerTextLink: {
      color: theme.palette.common.white,
      textDecoration: "none",
      display: "block",
      transition: "0.3s ease-in-out",
      paddingTop: theme.spacing(0.5),
      "&:hover": {
        transition: "0.3s ease-in-out",
        color: theme.palette.primary.main,
      },
    },
    socialContainer: {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(4),
      },
    },
    socialIcon: {
      color: theme.palette.common.white,
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(-2),
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        paddingRight: theme.spacing(2),
      },
    },
    copyright: {
      textAlign: "right",
      fontFamily: "Calibri",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
      },
    },
    footerTextExtraPadding: {
      paddingBottom: theme.spacing(1),
      textDecoration: "none",
      color: theme.palette.common.white,
    },
    footerGreyLinksContainer: {
      textAlign: "left",
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    footerGreyLink: {
      marginRight: theme.spacing(5),
      transition: "0.3s ease-in-out",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
      "&:hover": {
        transition: "0.3s ease-in-out",
        color: theme.palette.primary.main,
      },
    },
  })
);

/* eslint-disable-next-line */
export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const classes = useStyles(props);
  return (
    <footer className={classes.root}>
      <Container
        className={classes.contacts}
        maxWidth={false}
        disableGutters
        data-testid="footer-grid-container"
      >
        <Container>
          <Typography variant="h3">You can contact us here</Typography>
          <TitleDivider />

          <Typography
            variant="body2"
            className={classes.footerTextExtraPadding}
          >
            <strong>RICON GmbH & Co. KG</strong>
          </Typography>
          <Typography
            variant="body2"
            className={classes.footerTextExtraPadding}
          >
            Lüneburger Str. 1
            <br />
            49401 Rieste
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="tel: +49 5491 9692-0"
            className={classes.footerTextLink}
          >
            Telefon: +49 5491 9692-0
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="mailto: info@ricon.de"
            className={classes.footerTextLink}
          >
            Email: info@ricon.de
          </Typography>
          <Typography
            variant="body2"
            component="a"
            href="https://www.ricon.de/ricon"
            target="_blank"
            className={classes.footerTextLink}
          >
            Website: www.ricon.de
          </Typography>
        </Container>
      </Container>
      <Container
        className={classes.nav}
        maxWidth={false}
        disableGutters
        data-testid="footer-nav-container"
      >
        <Container>
          <Grid container>
            <Grid
              item
              component="nav"
              xs={12}
              md={8}
              className={classes.footerGreyLinksContainer}
            >
              {footerBarLinks.map((element) => (
                <Typography
                  key={element.index}
                  variant="body1"
                  className={classes.footerGreyLink}
                  href={element.to.pathname}
                  component="a"
                >
                  {element.default}
                </Typography>
              ))}
            </Grid>
            <Grid
              item
              component="p"
              xs={12}
              md={4}
              className={classes.copyright}
            >
              &copy; RICON GmbH &amp; Co. KG 2020
            </Grid>
          </Grid>
        </Container>
      </Container>
    </footer>
  );
};

import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { TitleDivider } from "../../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ffRoot: {
      backgroundColor: "white",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(5),
      },
    },
    ffContainer: {
      display: "flex",
      minHeight: "65vh",
      height: "600px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        height: "auto",
        minHeight: "auto",
      },
    },
    orderRegular: {
      width: "calc(50% - 150px)",
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "auto",
      },
    },
    orderInverted: {
      width: "calc(50% - 150px)",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "auto",
      },
    },
    inverse: {
      flexDirection: "row-reverse",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    overlay: {
      backgroundBlendMode: "multiply",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    textContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        marginRight: "auto",
        maxWidth: "600px",
        paddingTop: 0,
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    textContainerInverted: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(3),
      paddingRight: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        marginRight: "auto",
        maxWidth: "600px",
        paddingTop: 0,
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    ffIconContainer: {
      background: "white",
      width: "80px",
      height: "80px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      boxShadow: "2px 2px 10px #00000080",
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    ffIconRegular: {
      width: "40%",
    },
    ffImageContainer: {
      width: "calc(50% + 150px)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      boxShadow: "0px 0px 4px 2px #00000060",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "240px",
      },
    },
    ffDataContainer: {
      display: "flex",
      justifyContent: "center",
      width: "490px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        paddingTop: theme.spacing(3),
      },
    },
    cart: {
      position: "relative",
      right: "2px",
      width: "40px",
    },
    ffTitle: {
      wordBreak: "break-word",
    },
    divider: {},
  })
);

export interface FiftyFiftyProps {
  children?: React.ReactNode;
  img?: string;
  inverted?: boolean;
  title?: string;
  icon?: string;
  iconClass?: string;
  imgClass?: string;
}

export const FiftyFifty = (props: FiftyFiftyProps) => {
  const classes = useStyles(props);
  const imageUrlStyle = `url('${props.img}')`;

  return (
    <div className={classes.ffRoot}>
      <div
        data-testid="fifty-fifty-grid"
        className={classNames(
          { [classes.inverse]: props.inverted },
          classes.ffContainer
        )}
      >
        <div
          data-testid="left-column"
          className={
            props.inverted ? classes.orderInverted : classes.orderRegular
          }
        >
          <div className={classes.ffDataContainer}>
            <div
              className={
                props.inverted
                  ? classes.textContainer
                  : classes.textContainerInverted
              }
            >
              <div className={classes.ffIconContainer}>
                <img
                  src={props.icon}
                  alt={props.title}
                  title={props.title}
                  className={`${classes.ffIconRegular} 
                
               
                 
                  `}
                />
              </div>
              <Typography variant="h2" className={classes.ffTitle}>
                {props.title}
              </Typography>
              <TitleDivider className={classes.divider} />
              <Typography variant="body1">{props.children}</Typography>
            </div>
          </div>
        </div>
        <div
          data-testid="right-column"
          className={`${classes.ffImageContainer} ${classes.overlay}`}
          style={{
            backgroundImage: imageUrlStyle,
          }}
        ></div>
      </div>
    </div>
  );
};

export default FiftyFifty;

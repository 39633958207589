export const footerBarLinks = [
  {
    index: 0,
    title: "RICON_impressum",
    default: "Imprint",
    to: { pathname: "https://www.ricon.de/ricon/page/ricon-impressum" },
  },

  {
    index: 3,
    title: "RICON_datenschutz",
    default: "Privacy Policy",
    to: { pathname: "https://www.ricon.de/ricon/page/ricon-datenschutz" },
  },
  {
    index: 4,
    title: "RICON_agb",
    default: "GTC",
    to: { pathname: "https://www.ricon.de/ricon/page/agb-ricon" },
  },
];

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { VegetablesInformationCard } from "..";
import { vegetablesData } from "../../data/vegetablesSectionData";
import "./styles.css";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vegetablesDataContainer: { maxWidth: "1400px" },
    vegetablesDetailsWrapper: {
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(6, 3, 5, 3),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 1),
      },
    },
    vegetableItemWrapper: {
      display: "flex",
      flexGrow: 1,
      height: "250px",
      maxHeight: "400px",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(2),
      [theme.breakpoints.down("lg")]: {
        width: "12%",
      },
      [theme.breakpoints.down("md")]: {
        width: "25%",
      },

      [theme.breakpoints.down("xs")]: {
        width: "50%",
      },
    },
    descriptionWrapper: {
      padding: theme.spacing(0, 25, 2, 25),

      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0, 3, 2, 4),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2, 2),
        paddingTop: theme.spacing(4),
      },
    },
    descriptionButtonWrapper: {
      padding: theme.spacing(0, 25, 2, 25),

      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0, 3, 2, 4),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1, 2),
      },
    },
    slider: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(6, 3, 5, 3),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 1),
      },
    },
  })
);

export default function ProductSlider() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
  });
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: screenWidth > 600 ? 4 : 2,
    slidesToScroll: 1,
    nextArrow: <KeyboardArrowRight />,
    prevArrow: <KeyboardArrowLeft />,
  };
  const classes = useStyles();

  return (
    <Slider {...settings} className={classes.slider}>
      {vegetablesData.map((element: any, index: number) => (
        <div className="vegetableItemWrapper" key={index}>
          <img src={element.image} alt="" width="100px" />
          <VegetablesInformationCard information={element.information} />
        </div>
      ))}
    </Slider>
  );
}

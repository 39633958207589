import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import React from "react";
import { Button, Timeline, TitleDivider } from "../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerWrapper: {
      position: "relative",
      background: theme.palette.grey[200],
    },
    backgroundVideo: {
      objectFit: "cover",
      width: "100%",

      position: "absolute",

      left: 0,
      zIndex: 0,
      [theme.breakpoints.up("sm")]: {
        height: "94vh",
      },
    },

    banner: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: "700px",

      color: theme.palette.common.white,
      backgroundBlendMode: "multiply",
      [theme.breakpoints.down("sm")]: {
        minHeight: "500px",
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),

        paddingBottom: theme.spacing(2),
      },
    },
    fullHeight: {
      height: "100%",
    },

    whiteLogo: {
      zIndex: 5,
      paddingLeft: theme.spacing(1),
    },
    buttonScroll: {
      color: theme.palette.primary.main,
      textAlign: "center",
      zIndex: 3,
      transition: [
        theme.transitions.create("color"),
        theme.transitions.create("transform"),
      ].join(","),
      "&:hover, &:focus": {
        color: theme.palette.common.white,
        transform: `translateY(${theme.spacing(1)}px)`,
        cursor: "pointer",
      },
    },
    subHeading: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    subDetail: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    arrowIndicatorRegular: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    detailsWrapper: {
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
    },
    contactNumber: {
      marginLeft: theme.spacing(1),
      color: theme.palette.common.white,
      textDecoration: "none",
      display: "block",
      transition: "0.3s ease-in-out",
    },
    registerButtonWrapper: {
      textDecoration: "none",
    },
    navItems: {
      display: "flex",
      justifyContent: "space-between",
      zIndex: 5,
      width: "100%",
      alignItems: "center",
    },
    inquireBtn: {
      textDecoration: "none",
    },
  })
);

/* eslint-disable-next-line */
interface BannerProps {}

export const Banner = (props: BannerProps) => {
  const matchMobileDevices = useMediaQuery("(max-width:600px)");
  const bannerHeight = window?.innerHeight || "100vh";
  const classes = useStyles(props);
  const backgroundUrl = `radial-gradient(ellipse at center, 
            rgba(35,35,35,0.1) 0%,rgba(35,35,35,0.65) 100%
          )`;
  const backgroundHeight = matchMobileDevices ? bannerHeight : "100vh";

  return (
    <div className={classes.bannerWrapper}>
      <Container
        maxWidth={false}
        className={classes.banner}
        style={{
          backgroundImage: backgroundUrl,
          height: backgroundHeight,
        }}
        disableGutters
      >
        <div
          style={{
            width: "100%",
            height: bannerHeight || "100vh",
            zIndex: 2,
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background:
              "radial-gradient(ellipse at center,rgba(35,35,35,0.1) 0%,rgba(35,35,35,0.65) 100%",
          }}
        ></div>
        <video
          autoPlay
          muted
          loop
          id="myVideo"
          className={classes.backgroundVideo}
          style={{ height: bannerHeight }}
        >
          <source src="/videos/planebe_banner.mp4" type="video/mp4" />
        </video>

        <Container className={classes.fullHeight}>
          <Grid
            container
            justify="space-between"
            direction="row"
            className={classes.fullHeight}
          >
            {/* <div className={classes.navItems}>
              <img
                src="/img/plan_be_logo.svg"
                alt=""
                className={classes.whiteLogo}
              />
            </div> */}
            <Grid item xs={12} />

            <Grid
              container
              item
              xs={12}
              alignItems="center"
              style={{ zIndex: 2 }}
            >
              <Grid item>
                <Grid item sm={6}>
                  <Typography variant="h1">
                    Just brush off your problems!
                  </Typography>
                  <TitleDivider />
                  <Typography variant="h4" className={classes.detailsWrapper}>
                    <span className={classes.subDetail}>
                      Our food scrapers -{" "}
                    </span>
                    For a long term and smooth production process
                  </Typography>

                  <a
                    href="https://abfrage.typeform.com/to/wBFsTOKI"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.inquireBtn}
                  >
                    <Button variant="primary" modifier="bright">
                      Request now
                    </Button>
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} alignContent="flex-end">
              <Grid
                container
                item
                xs={12}
                justify="center"
                className={classes.arrowIndicatorRegular}
              >
                <div role="button" className={classes.buttonScroll}>
                  <KeyboardArrowDownRoundedIcon fontSize="large" />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Timeline />
    </div>
  );
};

export default Banner;

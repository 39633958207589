import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { TitleDivider } from "../components";
import { data } from "../data/timelineData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timelineWrapper: {
      position: "absolute",
      right: 0,
      zIndex: 3,
      transform: "translate(0%,-50%)",
      top: "50%",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        position: "relative",
        transform: "none",
        top: "auto",
        background: theme.palette.grey[200],
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
      },
    },
    divider: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    timelineChipsRegular: {
      position: "relative",
      marginBottom: theme.spacing(3),
      background: "rgba(0, 92, 125, 0.7)",

      height: "80px",
      borderTopLeftRadius: "40px",
      borderBottomLeftRadius: "40px",
      minWidth: "310px",
      display: "flex",
      alignItems: "center",
      alignContent: "center",

      zIndex: 4,

      [theme.breakpoints.down("sm")]: {
        minWidth: "auto",
        background: "transparent",
        height: "50px",
      },
      "&:nth-child(-n+2)::after": {
        content: '" "',
        display: "block",
        height: "24px",
        width: "10px",
        background: "rgba(0, 92, 125, 0.7)",
        position: "absolute",
        top: "80px",
        left: "35px",
        zIndex: 1,

        [theme.breakpoints.down("sm")]: {
          top: "50px",
          left: "20px",
        },
      },
    },
    timelineChipsDataRegular: {
      wordBreak: "break-word",
      color: "white",
      lineHeight: "1.1",
      marginLeft: theme.spacing(2),
      maxWidth: "200px",
      fontSize: "18px",

      fontWeight: "lighter",
      [theme.breakpoints.down("sm")]: {
        color: "black",
        maxWidth: "150px",
        fontSize: "15px",
      },
    },
    timelineChipsIconContainer: {
      width: "80px",
      height: "80px",
      background: "white",
      borderRadius: "50%",
      boxShadow: "-1px 1px 8px 4px rgba(0,0,0,0.45)",
      display: "flex",
      justifyContent: "center",
      opacity: "1",
      alignContent: "center",

      alignItems: "center",
      zIndex: 2,
      [theme.breakpoints.down("sm")]: {
        width: "50px",
        height: "50px",
      },
    },
    timelineChipsIconRegular: {
      width: "50px",

      [theme.breakpoints.down("sm")]: {
        width: "30px",
      },
    },
    timelineTitle: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    timelineChipsContainer: {
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2),
        marginBottom: 0,
      },
    },
  })
);

/* eslint-disable-next-line */
interface TimelineProps {}

export const Timeline = (props: TimelineProps) => {
  const classes = useStyles(props);

  return (
    <div className={classes.timelineWrapper}>
      <Typography variant="h2" className={classes.timelineTitle}>
        Your Advantages
      </Typography>
      <TitleDivider className={classes.divider} />
      <div
        data-testid="timeline-chips"
        className={classes.timelineChipsContainer}
      >
        {data?.map((element, index) => {
          return (
            <div key={index} className={classes.timelineChipsRegular}>
              <div className={classes.timelineChipsIconContainer}>
                <img
                  src={element.image}
                  className={classes.timelineChipsIconRegular}
                  alt=""
                />
              </div>
              <Typography
                variant="body1"
                component="span"
                className={classes.timelineChipsDataRegular}
              >
                {element.information}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Timeline;

import React, { createContext, FunctionComponent, useContext } from "react";
import { FormattedData } from "./types";
import { useFetchDe, useFetchEn } from "./useFetch";
import { makeEmptyData } from "./utils";

const LanguageContext = createContext("");

const useLanguage = () => useContext(LanguageContext);

export const useContent = (slugs?: string[]) => {
  const language = useLanguage();
  const contentDe = useFetchDe();
  const contentEn = useFetchEn();

  const content = language === "en" ? contentEn : contentDe;

  if (!slugs) {
    return content;
  }
  const toReturn: FormattedData = {};

  slugs.forEach(
    (slug: string) => (toReturn[slug] = content[slug] ?? makeEmptyData(slug))
  );
  return toReturn;
};

interface ContentProviderProps {
  language: any;
}

export const ContentProvider: FunctionComponent<ContentProviderProps> = ({
  language,
  children,
}) => (
  <LanguageContext.Provider value={language}>
    {children}
  </LanguageContext.Provider>
);

import { ThemeProvider } from "@material-ui/core";
import React from "react";
import {
  Banner,
  CallToAction,
  ContactAssistant,
  FiftyFifty,
  Footer,
  Navbar,
  VegetablesSection,
} from "./components";
import { ContentProvider } from "./fetch";
import theme from "./theme/src";

function App({ language }: { language: string }) {
  return (
    <ContentProvider language={language}>
      <ThemeProvider theme={theme}>
        <div style={{ position: "relative" }}>
          <Navbar />
          <ContactAssistant />

          <Banner />
          <VegetablesSection />
          <FiftyFifty
            img="/img/hygiene.png"
            title="Low mainteance"
            icon="/img/wrench.svg"
          >
            An enquiry from the food industry, combined with a detailed
            requirements profile, was the cornerstone for the development of our
            scrapers. What began as a special development for one company and
            one product area has since developed into a broad product range for
            the manufacturing industry, with special attention to hygiene and
            maximum product efficiency.
          </FiftyFifty>
          <FiftyFifty
            img="/img/transportband.png"
            title="Tailored for you"
            icon="/img/solving.svg"
            inverted={true}
          >
            A specially developed polyurethane body, cast with a stainless steel
            support tube or shaft, fits snugly against the belt cover or roller,
            responding extremely flexibly to the product flow. The standard
            scraper is the simplest solution for separating product residues
            from the belt or roller. The predominant installation position as a
            front or drum scraper enables uncomplicated and quick installation.
          </FiftyFifty>
          <FiftyFifty
            img="/img/kombination.png"
            title="Low process costs"
            icon="/img/dollar.svg"
          >
            Nevertheless, technically unsuitable and hygienically questionable
            scrapers are still being used. Also, when wipers are first fitted,
            the special features with regard to product and product behaviour
            are often not known or turn out differently in production operations
            than initially expected. So find the right combination for your
            company and save both resources and time.
          </FiftyFifty>

          <CallToAction
            title="Many years of experience, strong solutions!"
            buttonText="Request now"
            img="/img/calltoaction.png"
          >
            We have known what is important for a long time. No matter what you
            need, we will find the perfect wiper solution for you.
          </CallToAction>

          <Footer />
        </div>
      </ThemeProvider>
    </ContentProvider>
  );
}

export default App;
